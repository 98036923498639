import ConfirmAdministrator from 'api/confirm-administrator'

import constants from '../constants/confirm-administrator'

export const confirmAdmin = (token) => async (dispatch, getState) => {
  dispatch({
    type: constants.CONFIRM_ADMIN_REQUEST
  })

  const admin = new ConfirmAdministrator()
  const { id } = getState().currentSite.site
  try {
    const response = await admin.confirm(id, token)

    dispatch({
      type: constants.CONFIRM_ADMIN_SUCCESS,
      siteData: {
        id: response.id,
        ...response.attributes()
      }
    })
  } catch (response) {
    dispatch({
      type: constants.CONFIRM_ADMIN_FAILURE,
      errorMessage: response.message || response.statusText || response.status
    })
  }
}

export const inviteAdmin = (screenName, email) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: constants.INVITE_ADMIN_REQUEST
  })

  const admin = new ConfirmAdministrator({
    email,
    roleName: 'admin',
    screenName
  })

  const { id } = getState().currentSite.site
  try {
    const response = await admin.invite(id)
    dispatch({
      type: constants.INVITE_ADMIN_SUCCESS,
      siteData: {
        id: response.id,
        ...response.attributes()
      }
    })
  } catch (response) {
    dispatch({
      type: constants.INVITE_ADMIN_FAILURE,
      errorMessage: response.message || response.statusText || response.status,
      errors: { ...response.data.errors }
    })
  }
}
