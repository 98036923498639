import Model from '@itsfadnis/jsonapi-client'
import Errors from '@itsfadnis/jsonapi-client/dist/jsonapi-error'

class ConfirmAdministrator extends Model {
  static _type = 'sites';

  static baseURL = '/sites';

  constructor (args = {}) {
    super(args)

    Model.call(this, args)

    this.login = args.login
    this.email = args.email
    this.createdAt = args.createdAt
    this.lastSeenAt = args.lastSeenAt
    this.roleName = args.roleName
    this.blocked = args.blocked
    this.unconfirmed = args.unconfirmed
    this.roleBeforeBlocking = args.roleBeforeBlocking
    this.accessibleProjectsCount = args.accessibleProjectsCount
    this.accessibleHubsCount = args.accessibleHubsCount
    this.accessibleProjectsIds = args.accessibleProjectsIds
    this.screenName = args.screenName || ''
  }

  confirm (siteId, token) {
    return this.constructor.adapter
      .get(`${this.constructBaseURL()}/${siteId}/admin_invitations/${token}`)
      .then(({ data }) => this.constructor.deserialize(data))
      .catch((err) => {
        this.errors = new Errors(err.data)
        throw err
      })
  }

  invite (siteId) {
    return this.constructor.adapter
      .post(`${this.constructBaseURL()}/${siteId}/admin_invitations`, {
        data: {
          attributes: {
            email: this.email,
            role_name: this.roleName,
            screen_name: this.screenName
          }
        }
      })
      .then(({ data }) => this.constructor.deserialize(data))
      .catch((err) => {
        this.errors = new Errors(err.data)
        throw err
      })
  }
}

export default ConfirmAdministrator
