'use strict'

import Model from '@itsfadnis/jsonapi-client'
import Errors from '@itsfadnis/jsonapi-client/dist/jsonapi-error'
import extend from './internal/extend'

function Token () {
  var args = arguments[0] !== undefined ? arguments[0] : {}
  Model.call(this, args)
  this.login = args.login
  this.password = args.password
  this.token = args.token
  this.refreshToken = args.refreshToken
  this.expiresIn = args.expiresIn
}

extend(Token, Model)

Token.baseURL = '/tokens'

Token.prototype.refresh = function () {
  var self = this
  return this.constructor.adapter
    .post(this.constructBaseURL() + '/refresh', this.serialize())
    .then(function (response) {
      return self.constructor.deserialize(response.data)
    })
    .catch(function (err) {
      self.errors = new Errors(err.data)
      throw err
    })
}

Token.prototype.getToken = function () {
  var self = this
  return this.constructor.adapter
    .post(this.constructBaseURL(), this.serialize())
    .then(function (response) {
      return self.constructor.deserialize(response.data)
    })
    .catch(function (err) {
      self.errors = new Errors(err.data)
      throw err
    })
}

Token._type = 'tokens'

module.exports = Token
