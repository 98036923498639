import constants from 'constants/signup-form-questions'
import SignupFormQuestions from 'api/signup-form-question'
import { extractSignupForm } from 'lib/signup-form-helper'
import { setup } from 'lib/adapter'

export const fetchSignupFormQuestions = () => async (dispatch, getState) => {
  dispatch({
    type: constants.FETCH_QUESTIONS_REQUEST
  })

  try {
    if (typeof window !== 'object') {
      setup(getState().currentUser.adapter)
    }

    const response = await SignupFormQuestions.fetchAll()
    dispatch({
      type: constants.FETCH_QUESTIONS_SUCCESS,
      ...extractSignupForm(response)
    })
  } catch (response) {
    dispatch({
      type: constants.FETCH_QUESTIONS_FAILURE,
      errorMessage: response.statusText || response.status
    })
  }
}

export const updateSignupFormQuestions = (key, value) => {
  return {
    type: constants.UPDATE_QUESTIONS,
    key,
    value
  }
}

export const saveSignupFormQuestions = () => async (dispatch, getState) => {
  dispatch({
    type: constants.SAVE_QUESTIONS_REQUEST
  })

  const signupFormQuestions = new SignupFormQuestions(getState().signupFormQuestions.signupFormQuestions)

  try {
    await signupFormQuestions.save()
    dispatch({
      type: constants.SAVE_QUESTIONS_SUCCESS
    })
  } catch (response) {
    dispatch({
      type: constants.SAVE_QUESTIONS_FAILURE,
      errorMessage: response.message || response.statusText || response.status,
      errors: signupFormQuestions.errors.extract()
    })
  }
}
