import { confirmAdmin } from 'actions/confirm-administrator';
import { fetchFeatures } from 'actions/features';
import { updateIntercom } from 'actions/libraries';
import { fetchSettings } from 'actions/settings';
import { fetchSignupFormQuestions } from 'actions/signup-form-questions';
import { fetchSite } from 'actions/site';
import { fetchUser } from 'actions/user';
import { HubAdmin, PlatformAdmin, ProjectAdmin, SiteAdmin } from 'lib/roles';
import { isEmpty } from 'lodash';
import { NextComponentType, NextPageContext } from 'next';
import Head from 'next/head';
import React, { Component, Fragment } from 'react';

interface IPageContext extends NextPageContext {
  reduxStore: any;
}

interface IProps {
  siteName: string;
  siteLogo: string;
  faviconUrl: string;
  siteUrl: string;
}

export default (App: NextComponentType, isConfirmPasswordPage = false) =>
  class WithAdminOnBoardingPageLayout extends Component<IProps> {
    static async getInitialProps(ctx: IPageContext) {
      if (typeof window !== 'object') {
        // Fetch current site if not present
        const { currentSite } = ctx.reduxStore.getState();

        const isCurrentSitePresent =
          currentSite.doneFetching && !currentSite.fetchError;
        if (!isCurrentSitePresent) {
          // @ts-ignore
          await ctx.reduxStore.dispatch(fetchSite(''));
        }

        // Fetch features if not present
        const { features } = ctx.reduxStore.getState();

        const isFeaturesPresent = features.doneFetching && !features.fetchError;
        if (!isFeaturesPresent) {
          await ctx.reduxStore.dispatch(fetchFeatures());
        }

        if (isEmpty(ctx.reduxStore.getState()?.librariesDetails?.intercom)) {
          await ctx.reduxStore.dispatch(updateIntercom());
        }

        const isSiteOnboardingEnabled =
          Object.prototype.hasOwnProperty.call(
            ctx.reduxStore.getState().features.features,
            'enable_site_onboarding',
          ) &&
          ctx.reduxStore.getState().features.features['enable_site_onboarding']
            .enabled;

        // If the feature toggle is off then don't render admin login or confirm password page.
        if (!isSiteOnboardingEnabled) {
          ctx.res?.writeHead(307, {
            Location: '/login',
          });

          ctx.res?.end();

          return;
        }

        const isSiteBlocked =
          ctx.reduxStore.getState().currentSite.site.blocked;

        // If the feature toggle is off and the site is not blocked then don't show the admin login page
        if (!isSiteBlocked && !isConfirmPasswordPage) {
          ctx.res?.writeHead(307, {
            Location: '/login',
          });

          ctx.res?.end();

          return;
        }

        // Fetch current user if not present
        const { currentUser } = ctx.reduxStore.getState();

        const isCurrentUserPresent =
          currentUser.doneFetching && !currentUser.fetchError;
        if (!isCurrentUserPresent) {
          await ctx.reduxStore.dispatch(fetchUser('current'));
        }

        const currentUserRole =
          ctx.reduxStore.getState().currentUser.user.roleName;

        const isAnAdmin =
          [SiteAdmin, PlatformAdmin, HubAdmin, ProjectAdmin].indexOf(
            currentUserRole,
          ) !== -1;

        // If the logged in user is admin then redirect to the dashboard page
        if (isAnAdmin) {
          ctx.res?.writeHead(307, {
            Location: '/admin/dashboard',
          });

          ctx.res?.end();

          return;
        }

        // Load site settings in admin login page for SSO login
        if (!isConfirmPasswordPage) {
          const { siteSettings } = ctx.reduxStore.getState();
          const isSiteSettingsPresent =
            siteSettings.doneFetching && !siteSettings.fetchError;
          if (!isSiteSettingsPresent) {
            await ctx.reduxStore.dispatch(fetchSettings());
          }
        }

        // This block of code should only run on the confirm password page
        if (isConfirmPasswordPage) {
          const confirmationToken = ctx.query.token;

          await ctx.reduxStore.dispatch(confirmAdmin(confirmationToken));

          const isAdminNotConfirmed =
            ctx.reduxStore.getState().confirmAdministrator.siteData.unconfirmed; // If the admin is unconfirmed then it returns true else 404

          // If the admin has already set the password then redirect it to the login page.
          if (!isAdminNotConfirmed) {
            ctx.res?.writeHead(307, {
              Location: '/admin/login',
            });

            ctx.res?.end();

            return;
          }

          // This is important to fetch as we have to pass the password question id
          await ctx.reduxStore.dispatch(fetchSignupFormQuestions());
        }

        let appProps = {};

        if (typeof App.getInitialProps === 'function') {
          appProps = await App.getInitialProps(ctx);
        }

        return {
          ...appProps,
          siteName: ctx.reduxStore.getState().currentSite.site.name,
          siteLogo: ctx.reduxStore.getState().currentSite.site.logoUrl,
          faviconUrl: ctx.reduxStore.getState().currentSite.site.faviconUrl,
          siteUrl: ctx.reduxStore.getState().currentSite.site.domain,
          signupFormQuestions: ctx.reduxStore.getState().signupFormQuestions,
        };
      }
    }

    render() {
      const { siteName, faviconUrl } = this.props;

      return (
        <Fragment>
          <Head>
            <>
              <title>{siteName}</title>
              <link href={faviconUrl} rel="icon" type="image/ico" />
            </>
          </Head>
          <App {...this.props} />
        </Fragment>
      );
    }
  };
