import Model from '@itsfadnis/jsonapi-client'

class SignupFormQuestion extends Model {
  static _type = 'signup-form-questions';

  static baseURL = '/signup_form_questions';

  constructor (args = {}) {
    super(args)

    Model.call(this, args)

    this.answered = args.answered || false
    this.checkboxAnswersLimit = args.checkboxAnswersLimit || null
    this.deleted = args.deleted || false
    this.maxLength = args.maxLength || null
    this.notes = args.notes || ''
    this.question = args.question || ''
    this.parentId = args.parentId || null
    this.questionOptions = args.questionOptions || []
    this.required = args.required || false
    this.type = args.type || ''
    this.imageUrl = args.imageUrl || ''
    this.hasTrigger = args.hasTrigger || false
    this.triggerOptionId = args.triggerOptionId || null
    this.triggerQuestionId = args.triggerQuestionId || null
    this.horizontal = args.horizontal || false
    this.logicOperator = args.logicOperator || false
    this.sequence = args.sequence || ''
    this.yearOnlyDate = args.yearOnlyDate || false
  }
}

export default SignupFormQuestion
