type credentials = {
  emailOrLogin: string;
  password: string;
  rememberMe: string;
};

export const signInUser =
  (credentials: credentials, isOnSiteOnBoarding = false, primaryDomainURL) =>
  (): void => {
    const { emailOrLogin, password, rememberMe } = credentials;

    const url = primaryDomainURL
      ? `https://${primaryDomainURL}/users/session`
      : '/users/session';

    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', url);
    form.style.display = 'none';

    const emailEl = document.createElement('input');
    emailEl.setAttribute('name', 'user[email_or_login]');
    emailEl.setAttribute('value', emailOrLogin);

    const passwordEl = document.createElement('input');
    passwordEl.setAttribute('name', 'user[password]');
    passwordEl.setAttribute('value', password);

    const rememberMeEl = document.createElement('input');
    rememberMeEl.setAttribute('name', 'user[remember_me]');
    rememberMeEl.setAttribute('value', rememberMe);

    form.appendChild(emailEl);
    form.appendChild(passwordEl);
    form.appendChild(rememberMeEl);

    if (isOnSiteOnBoarding) {
      const siteOnboardingEl = document.createElement('input');
      siteOnboardingEl.setAttribute('name', 'user[site_onboarding]');
      siteOnboardingEl.setAttribute('value', 'true');

      form.appendChild(siteOnboardingEl);
    }

    document.body.appendChild(form);
    form.submit();
  };
