import Token from 'api/token'
import Cookies from 'js-cookie'

import constants from '../constants/token'

export const fetchToken = (credentials) => async (dispatch, getState) => {
  dispatch({
    type: constants.FETCH_TOKEN_REQUEST
  })

  try {
    const token = new Token(credentials)
    const response = await token.getToken()

    Cookies.set('unexpected_thunder', response.token)

    dispatch({
      type: constants.FETCH_TOKEN_SUCCESS,
      token: response
    })
  } catch (error) {
    dispatch({
      type: constants.FETCH_TOKEN_FAILURE,
      errorMessage: error.status
    })
  }
}

export const resetToken = () => {
  return {
    type: constants.RESET_TOKEN
  }
}
