const isConditionalQuestion = (question, questions, options) => {
  if (!question.hasTrigger ||
    typeof question.logicOperator !== 'boolean' ||
    !question.triggerQuestionId ||
    !question.triggerOptionId
  ) { return false }

  return questions.hasOwnProperty(`${question.triggerQuestionId}`) &&
    options.hasOwnProperty(`${question.triggerOptionId}`)
}

/**
* Segregate questions into parent & conditional questions
*
* @param {Object} questions A hash of questions indexed by id
* @param {Object} options A hash of options indexed by id.
* @returns {Object}
* {
*    parentQuestions: <Array> sorted parent questions,
*    conditionalQuestions: <Object> A hash of sorted conditional questions indexed by parent id
* }
* @public
*/
export const segregateQuestions = (questions, options) => {
  const parentQuestions = []
  const conditionalQuestions = {}

  for (const key in questions) {
    const question = questions[key]
    if (isConditionalQuestion(question, questions, options)) {
      if (conditionalQuestions.hasOwnProperty(question.triggerQuestionId)) {
        conditionalQuestions[question.triggerQuestionId].push(question)
        conditionalQuestions[question.triggerQuestionId]
          .sort((a, b) => a.sequence - b.sequence)
      } else {
        conditionalQuestions[question.triggerQuestionId] = [question]
      }
    } else {
      parentQuestions.push(question)
    }
  }

  parentQuestions.sort((a, b) => a.sequence - b.sequence)

  return {
    parentQuestions,
    conditionalQuestions
  }
}

/**
* @param {Array} data An array of signup form questions
* @returns {Object} A hash of questions & options indexed by id
* @public
*/
export const extractSignupForm = (data) => {
  let questions = {}
  let options = {}

  data.forEach((object) => {
    if (!object.deleted && object.type !== 'SignupForm::TosQuestion') {
      const { questionOptions, ...question } = object
      questions[question.id] = question
      if (Array.isArray(questionOptions)) {
        questionOptions.forEach((option) => {
          if (!option.deleted) {
            options[option.id] = { ...option, questionId: object.id }
          }
        })
      }
    }
  })

  return {
    questions,
    options
  }
}

/**
* @param {Object} options An hash of options indexed by id
* @param {Number|String} questionId The question id for which we need options
* @returns {Array} A an array sorted options for the question
* @public
*/
export const extractQuestionOptions = (options, questionId) => {
  let questionOptions = []
  for (const key in options) {
    const option = options[key]
    if (option.questionId === questionId) {
      questionOptions.push(option)
    }
  }
  return questionOptions.sort((a, b) => a.sequence - b.sequence)
}

/**
* @param {Object} question
* @param {Object} answer
* @param {Array} children
* @returns {Array} A sorted array of triggered questions
* @public
*/
export const triggeredQuestions = (question, answer, children) => {
  const questions = []

  children.forEach((child) => {
    const { logicOperator, triggerOptionId } = child
    if (typeof answer.id === 'number' || typeof answer.id === 'string') {
      // It's a radio/dropdown answer
      if (logicOperator) {
        // eslint-disable-next-line eqeqeq
        if (answer.id == triggerOptionId) {
          questions.push(child)
        }
      } else {
        // eslint-disable-next-line eqeqeq
        if (answer.id != triggerOptionId) {
          questions.push(child)
        }
      }
    } else if (Array.isArray(answer.id)) {
      // It's a checkbox answer
      if (logicOperator) {
        // eslint-disable-next-line eqeqeq
        if (answer.id.some(a => a == triggerOptionId)) {
          questions.push(child)
        }
      } else {
        // eslint-disable-next-line eqeqeq
        if (answer.id.length > 0 && answer.id.every(a => a != triggerOptionId)) {
          questions.push(child)
        }
      }
    }
  })

  return questions.sort((a, b) => a.sequence - b.sequence)
}
